'use client'

import { useSession } from '@/auth/session.hook'
import { fetchProfileDetails } from '@/backend/apis/profile/GetProfileDetailsAPI'
import { UserTypeEnum } from '@/backend/models/User'
import { useQuery } from '@tanstack/react-query'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useMemo } from 'react'

export default function UserTypeChecker() {
  const pathname = usePathname()
  const router = useRouter()

  const session = useSession()

  const { data } = useQuery({
    queryKey: ['/profile', session.data?.user.id ?? ``],
    queryFn: async () => {
      if (!session.data) {
        return null
      }

      const res = await fetchProfileDetails(session.data.user.id)

      if (res.status === 'error') {
        return null
      }
      return res.data
    },
  })

  const isUnknownType = useMemo(
    () => !!data && data.userType === UserTypeEnum.value('unknown'),
    [data],
  )

  useEffect(() => {
    if (isUnknownType && pathname !== '/signup/selectuser') {
      router.push('/signup/selectuser')
    }
  }, [isUnknownType, pathname, router])
  return null
}
