import { cn } from '@/app/_primitives/utils/cn'
import toast, { ToastBar, Toaster } from 'react-hot-toast'
import { twMerge } from 'tailwind-merge'
import Close from '../Icon/Close'

function CustomToaster() {
  return (
    <Toaster position="bottom-center">
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            padding: '17px 20px',
            width: `387px`,
            maxWidth: '387px',
          }}>
          {({ icon, message }) => {
            const actualMessage = (message as any)?.props?.children?.props
              ?.children

            const title =
              actualMessage?.find(
                (child: any) => child?.props?.['data-slot'] === 'title',
              )?.props?.children ||
              (message as any)?.props?.children ||
              message

            const description = actualMessage?.find(
              (child: any) => child?.props?.['data-slot'] === 'description',
            )?.props?.children

            return (
              <div
                className={cn('flex flex-row w-full gap-8', {
                  'items-start': Boolean(description),
                })}>
                {description ? <div className={'mt-2'}>{icon}</div> : icon}
                <div
                  className={cn('flex grow justify-between', {
                    'items-start': Boolean(description),
                  })}>
                  {description ? (
                    <div className={'flex flex-1 flex-col items-stretch gap-6'}>
                      <span className="c-h7">{title}</span>
                      <span className="c-b4 text-[#8b8b8b]">{description}</span>
                    </div>
                  ) : (
                    <span
                      className={twMerge(
                        t.type === 'loading' ? `c-b3` : 'c-h7',
                      )}>
                      {title}
                    </span>
                  )}
                  {t.type !== 'loading' && (
                    <button type={'button'} onClick={() => toast.dismiss(t.id)}>
                      <Close fill={'#8B8B8B'} />
                    </button>
                  )}
                </div>
              </div>
            )
          }}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default CustomToaster
