import { WebLinkTypeEnum } from '@/backend/models/Common'
import { UserTypeEnum } from '@/backend/models/User'
import { z } from 'zod'

export const ProfileSchema = z.object({
  displayName: z.string().nullable(),
  phoneNumber: z.string().nullable(),
})

export const ProfileDetailsSchema = z.object({
  id: z.string(),
  humanReadableID: z.string(),
  email: z.string(),
  userType: z.lazy(() => UserTypeEnum.zEnum),
  imageURL: z.string().nullable(),
  displayName: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  introduction: z.string().nullable(),

  isVerified: z.boolean(),
  isBlocked: z.boolean(),

  webLinks: z.array(
    z.object({
      id: z.string(),
      url: z.string(),
      type: WebLinkTypeEnum.zEnum,
    }),
  ),

  counts: z.object({
    posts: z.number(),
    followers: z.number(),
    characters: z.number(),
  }),

  isFollowed: z.boolean(),
})

export type ProfileDetails = z.infer<typeof ProfileDetailsSchema>

export const ProfileUpdateFormSchema = z.object({
  name: z.string().min(1).max(60),
  imageURL: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  introduction: z.string().min(0).max(180),
  socialLinks: z.array(
    z.object({
      id: z.string(),
      type: z.string(),
      url: z.string(),
    }),
  ),
})

export type ProfileUpdateForm = z.infer<typeof ProfileUpdateFormSchema>
