'use client'

import { setTimezone } from '@/backend/apis/auth/SetCurrentUserTimezone'
import { useEffect } from 'react'

export function SetTimezone() {
  useEffect(() => {
    if (typeof window === 'undefined') return

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const savedTimezone = localStorage.getItem('timezone')

    if (timezone === savedTimezone) return

    setTimezone(timezone).then(() => localStorage.setItem('timezone', timezone))
  }, [])

  return null
}
